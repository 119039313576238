import Bugsnag from '@bugsnag/js';
import {
  addUserThunk,
  ApiErrorCodes,
  AuthDto,
  authenticateThunk,
  BaseTokenDto,
  cancelUsersThunk,
  configureSSOConfigurationThunk,
  createAdminMatchGroupMatchThunk,
  createSuggestionThunk,
  editAccountThunk,
  editUserThunk,
  fetchApprovalInfoThunk,
  fetchApprovalInfoUnauthenticatedThunk,
  MQApiError,
  processIntlBaseMessagesTranslateThunk,
  publishDraftProgramThunk,
  registerThunk,
  requestEnrollmentThunk,
  resetPasswordThunk,
  scheduleIntelligentMatchingThunk,
  testSSOLoginThunk,
  updateAdminMatchGroupMatchThunk,
  updateIntelligentMatchThunk,
  updatePeopleRolesThunk,
  updatePlatformConfigurationThunk,
  uploadChatFileThunk,
  uploadFileThunk,
  uploadHrisSyncFilesThunk,
  validateProgramEmailsThunk,
} from '@mentorcliq/storage';
import { PayloadAction } from '@reduxjs/toolkit';

import ROUTES from 'routes';

import { CustomErrorsEnum } from 'types/errors';
import { AppLocaleStorageKeysEnum } from 'types/global';

import { APP_ENV_CONFIGS } from 'definitions/configs';
import { ERRORS } from 'definitions/errors';

import alerts from 'helpers/alerts';
import caches from 'helpers/caches';
import history from 'helpers/history';

export const processStatus = (action: PayloadAction<MQApiError>) => {
  if (action.payload.subErrors) {
    for (const e of action.payload.subErrors) {
      switch (e.code) {
        case ApiErrorCodes.GdprNotAccepted:
          history.mq.push({
            pathname: ROUTES.acceptGdpr.data.path,
            search: history.mq.location.search,
          });
          break;
        case ApiErrorCodes.ViewAsNotAllowed:
        case ApiErrorCodes.LearningLabDisabled:
          history.mq.push(
            ROUTES.statusPage403.convert({
              search: {
                reason: e.code,
              },
            }),
          );
          break;
      }
    }
  } else {
    const status = Number(action.payload.code ?? 0);

    const errorRedirectPath = Object.values(ERRORS).find((err) => err.codes.includes(status))?.redirect;

    if (errorRedirectPath) {
      history.mq.push(errorRedirectPath);
    }
  }
  return action;
};
export const processError = (action: PayloadAction<MQApiError>) => {
  if (APP_ENV_CONFIGS.environments.production) {
    if (action.payload.apiError) {
      Bugsnag.notify(action.payload.apiError);
    }
  }
  switch (action.type) {
    case resetPasswordThunk.rejected.type:
    case authenticateThunk.rejected.type:
    case registerThunk.rejected.type:
    case requestEnrollmentThunk.rejected.type:
    case editUserThunk.rejected.type:
    case addUserThunk.rejected.type:
    case validateProgramEmailsThunk.rejected.type:
    case uploadFileThunk.rejected.type:
    case uploadChatFileThunk.rejected.type:
    case cancelUsersThunk.rejected.type:
    case updatePeopleRolesThunk.rejected.type:
    case configureSSOConfigurationThunk.rejected.type:
    case testSSOLoginThunk.rejected.type:
    case scheduleIntelligentMatchingThunk.rejected.type:
    case updateIntelligentMatchThunk.rejected.type:
    case fetchApprovalInfoThunk.rejected.type:
    case fetchApprovalInfoUnauthenticatedThunk.rejected.type:
    case processIntlBaseMessagesTranslateThunk.rejected.type:
    case createSuggestionThunk.rejected.type:
    case updatePlatformConfigurationThunk.rejected.type:
    case publishDraftProgramThunk.rejected.type:
    case editAccountThunk.rejected.type:
    case createAdminMatchGroupMatchThunk.rejected.type:
    case updateAdminMatchGroupMatchThunk.rejected.type:
    case uploadHrisSyncFilesThunk.rejected.type:
      alerts.addDanger({
        message: action.payload.message,
        body: action.payload.body,
        enableReinitialize: false,
      });
      break;
  }
  if (action.payload.code === CustomErrorsEnum.Econnaborted) {
    alerts.addCustom({
      message: '',
    });
    return action;
  }
  return processStatus(action);
};
export const refreshToken = (user: AuthDto, payload: BaseTokenDto) => {
  user.accessToken = payload.accessToken;
  user.expiresAt = payload.expiresAt;
  caches.locale.setItem({
    type: AppLocaleStorageKeysEnum.User,
    data: user,
  });
  return user;
};
export const createAuthHeaders = (token: string) => ({
  Authorization: `Bearer ${token}`,
});
